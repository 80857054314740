

import React from 'react'
import SeoPage from '../components/SeoPage'



const ContentPage22 = ({ data, location, ...props }) => (
    <SeoPage location={location}
        title="Soumission en ligne pour Avocat en droit pénal & droit criminel - Soumissions Avocat"
        description="Le réseau de Soumissions Avocat regroupe des avocats fiables et compétents pour vous aider en droit pénal: appel crimes d’ordre sexuel crimes économiques extradition infractions de la route/alcool au volant IVAC jeunes contrevenants libération conditionnelle pardon protection de la jeunesse stupéfiants violence conjugale vols autre Remplissez le formulaire de demande LIRE PLUS"
    >
        <h1>Soumission en ligne pour Avocat en droit pénal &amp; droit criminel</h1><p><strong>Le réseau de Soumissions Avocat regroupe des avocats fiables et compétents pour vous aider en droit pénal:</strong></p>
        <ul>
            <li>appel</li>
            <li>crimes d’ordre sexuel</li>
            <li>crimes économiques</li>
            <li>extradition</li>
            <li>infractions de la route/alcool au volant</li>
            <li>IVAC</li>
            <li>jeunes contrevenants</li>
            <li>libération conditionnelle</li>
            <li>pardon</li>
            <li>protection de la jeunesse</li>
            <li>stupéfiants</li>
            <li>violence conjugale</li>
            <li>vols</li>
            <li>autre</li>
        </ul>
        <p><strong>Remplissez le formulaire de demande de soumissions sur cette page pour obtenir votre offre de service gratuitement, rapidement et sans engagement.</strong> Nous avons des avocats partenaires dans votre secteur (Montréal, Québec comme ailleurs en province). Notre équipe vous mettra en contact avec un avocat en droit pénal qui correspond à vos besoins.</p>
    </SeoPage>
)
export default ContentPage22
